@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap);
@import url(https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css);
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: linear-gradient(to left, rgb(27 20 41), rgb(20 15 35)); */
}

html {
  --imp-text-color: #414141;
}

.blue {
  color: var(--imp-text-color) !important;
  font-weight: bold;
}

/* DARK THEME OPTIONS */

/* :root {
  --background: white;
  --text-primary: black;
  --text-secondary: royalblue;
  --accent: blue;
}

[data-theme='dark'] {
  --background: #05496d;
  --text-primary: white;
  --text-secondary: grey;
  --accent: darkred;
}

.dark-theme {
  color: var(--text-primary);
  background-color: var(--background);
  font-size: large;
  font-weight: bold;
  padding: 20px;
  height: calc(100vh - 40px);
  transition: all .5s;
} */

/* button {
  color: var(--text-primary);
  background-color: var(--background);
  border: 2px var(--text-primary) solid;
  float: relative;
  transition: all .5s;
} */

/* .col {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
} */

/* .col-md-4 {
    flex: 0 0 33.3333333%;
    max-width: 10%;
    position: relative;
    width: 100%;
    padding-right: 5px;
    padding-left: 5px;
    display: flex;
    float: left;
} */

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Adjust for the loader's size */
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 767px) {
    #preloader {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      z-index: 999999;
      background-color: #ffffff;
      background-repeat: no-repeat;
      background-position: center;
  }
}

#preloader-none {
  opacity: 0;
}

/* --------- */
/* Scrollbar   */
/* --------- */

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #064c72;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #096a9e;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1399E2;
  border-radius: 12px;
}

/* --------- */
/* BLOG Section  */
/* --------- */

.prose img {
  margin-left: auto;
  margin-right: auto;
  border-radius: 25px;
  /* width: auto; */
}


/* --------- */
/* Navbar  */
/* --------- */
.sticky {
  background-color: #096a9e !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px rgb(19, 153, 226) !important;
  -webkit-backdrop-filter: blur(15px) !important;
          backdrop-filter: blur(15px) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.1rem !important;
  background-color: #096a9e;;
}

.navbar-toggler {
  padding: 0.25rem 1.5rem !important;
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #ffffff !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: #096A9E !important;
  }
  .navbar-nav .nav-item a::after {
    display: flex !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 2.4em !important;
  width: 3.5em !important;
}

.navbar-nav .nav-link {
  color: white !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 35px;
  display: flex; /* new */
  flex-direction: row; /* new */
  justify-content: space-between; /* new */
  z-index: auto;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0px;
  border-radius: 16px;
  background: #ffffff;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home */
/* --------- */

.marquee-container {
  position: row;
  width: 100%vw;
  height: 12vh;
  text-align: center;
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.home-header-section {
  position: relative !important;
  background-image: var(--section-background-color) !important;
  border: white none;
  outline: none;
  max-width: auto;
  margin-top: 6% !important;
}

.home-header-content {
  margin-bottom: 5% !important;
}

.home-header {
  color: #414141 !important;
  justify-content: "center" !important;
}

@media (max-width: 767px) {
  .home-header-section {
    position: relative !important;
    background-image: var(--section-background-color) !important;
    max-width: 100vw;
    margin-top: 20% !important;
  }
  .home-header-content {
    margin-top: 5% !important;
    padding-bottom: 5% !important;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
  }
  .header-content {
    color: #414141;
    justify-content: "center" !important;
    justify: "center" !important;
    align-items: "center" !important;
    margin-bottom: 10% !important;
  }
}

.header-content {
  color: #414141;
  justify-content: center;
}

.heading { /* Top line (in heading) */
  font-size: 2.4em !important;
  margin-top: 25px !important;
}

.heading-description {
  margin-top: 15px !important;
}

.heading-name { /* Name listed (in heading) */
  font-size: 2.5em !important;
  background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading-additional-text { /* Additional text (in heading) */
  /* margin-left: 65px! important; */ 
}

.title-heading { /* rainbow effect */
  font-size: 2.5em !important;
  margin-top: 5%;
  margin-bottom: 10px;
  background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: grey !important;
  font-weight: 600 !important;
  font-type: "italic" !important;
}

.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #096A9E !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 600 !important;
    position: relative !important;
  }
  .Typewriter__cursor {
    display: none !important;
    color: #096A9E !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 40px !important;
}

.home-about-description {
  color: #414141 !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  /* font-size: 1.2em !important; */
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 35px;
  color: #fffff !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 10px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  display: flex;             /* Enable flexbox */
  justify-content: center;   /* Center horizontally */
  align-items: center;       /* Center vertically */
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
  background: #096a9e !important;
  border: 1px solid #096a9e;
  /* Additional properties */
  margin: auto;            /* Centers the flex container within its parent */
  cursor: pointer;         /* Add mouse pointer on hover */
  line-height: normal;     /* Aligns the text vertically */
  font-size: 0;            /* Hide the icon font */
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #096a9e;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover {
  color: #ffff;
  box-shadow: 0 0 5px #fff;
  text-shadow: 0 0 2px #fff;
}

.social-icons {
  display: inline-block !important;
  padding-left: 15px;
  height: 60px;
}

.icon-color {
  color: #ffffff !important;
}

/* --------- */
/* Footer */
/* --------- */
.footer {
  background-color: #096a9e;
  bottom: 0 !important;
}

.footer-copyright {
  text-align: center !important;
  margin-top: 20px;
}

.footer-row {
  justify-content:center;
}

.footer-body {
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copyright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #096a9e !important;
  font-weight: bold;
}


/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  border: white none;
  outline: none;
  max-width: 100vw;
}

.projects-row { /* the row which holds the list of projects */
  justify-content: center;
  padding-bottom: 10px;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
  border: white none;
  outline: none;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px #fff !important;
  color: #414141 !important;
  background-color: transparent !important;
  opacity: 1 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  border: white none;
  outline: none;
}

.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 4px #0c74ad !important;
  border: white none;
  outline: none;
}

.project-heading { /* rainbow effect */
  /* margin-top: 10%; */
  margin-bottom: 10px;
  background: linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-header { /* standard page title */
  color: #414141 !important;
  font-size: 2.3em !important;
  font-weight: 1000 !important;
  padding-top: 10px !important;
  padding-bottom: 15px;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: white !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: white !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

/* .btn-primary {
  color: #fff !important;
  background-color: #1399e2 !important;
  border-color: #1399e2 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #07699E !important;
  border-color: #07699E !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
} */

/* --------- */
/* About */
/* --------- */

.about-section {
  position: relative !important;
  padding-top: 100px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: #414141 !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid #414141 !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(99, 96, 102, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid #5a5959 !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: #414141 !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}



/* --------- */
/* Resume Page */
/* --------- */

.experience-column {
  display: flex;
  float: left;
  width: 45%;
}

.education-column {
     display: flex;
     float: left;
     width: 45%;
}

/* Create LINE WITH TEXT in Resume page component (AKA: Download My Resume subheader)*/
.separator{
  display: flex;
  align-items: center;
}

.separator h1{
  padding: 0 2rem; /* creates the space */
  color: #0281cd;
}

.separator .line{
  flex: 1 1;
  height: 3px;
  background-color: #0281cd;
}

/* Clear floats after the columns */
.resume-row:after {
  content: "";
  display: table;
  clear: both;
}

.resume-row {
  display: flex;
  width: 100%;
  padding-left: 10%;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}

/* RESUME DOWNLOAD BUTTON */
.download-button {
  background-color: #1399e2 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-width: 150px;
  border-radius: 15px;
}

.download-button:hover {
  background-color: #0c74ad !important;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* RESUME VIEWER SECTION */
.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: #414141 !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
  position: flex;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #5234795d;
  padding: 8px 15px;
  /* display: inline-block; */
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}


/* HOME PAGE BUTTON */
.submit-btn {
  background-color: #1399e2 !important;
  color: #fff;
  display: center;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  min-width: 150px;
  border-radius: 15px;
}

.submit-btn:hover {
  background-color: #0c74ad !important;
  color: #fff;
}

.image-style {
  height: 85%;
  width: 90%;
}

.project-title {
  font-weight: bold;
}

.profile-pic {
  border-radius: 25%;
  height: auto;
  width: 80vw;
  position: relative;
  margin-top: 0%;
}

@media (max-width: 767px) {
  .profile-pic {
    border-radius: 50%;
    height: auto;
    width: 90vw;
    position: relative;
    display: center;
    justify-content: center;
  }
}

.skill-tools-wrapper {
  flex-direction: row;
}

@media (max-width: 767px) {
  .skill-tools-wrapper{
    flex-direction: column;
  }
}

/* #tsparticles {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #323031;
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
} */
